/* eslint-disable camelcase */

import moment, { Moment } from "moment";
import Cookies from "js-cookie";
// import momentTz from "moment-timezone";
import moment_timezone from "moment-timezone/builds/moment-timezone-with-data-1970-2030";
import { MAX_USER_AGE } from "../validators/validatorConstants";
import { BO_CORE_DEFAULT_NUMBER_VALUE, BO_CORE_DEFAULT_VALUE } from "./AppUtils";

interface Overload {
    (): Date;
    (isMoment: false): Date;
    (isMoment: true): Moment;
}

const SHORT_DATE_REGEX = /^\d{4}-{1}\d{2}-{1}\d{2}$/;

export const momentDate = moment;

const timezone =
    Cookies.get("Time-Zone") ||
    Cookies.set("Time-Zone", moment_timezone.tz.guess(), {
        expires: momentDate().add(1000, "day").toDate(),
    });

export const formatDateTime = (date: any): string => {
    return date.format("L LT");
};

export const formatTime = (date: any): string => {
    return date.format("LT");
};

export const formatDate = (date: any): string => {
    if (momentDate.isMoment(date)) {
        const isValidD = date.isValid();
        return isValidD ? date.format("L") : "-";
    }
    const isValidDate = momentDate(date).isValid();
    return isValidDate ? momentDate(date).format("L") : "-";
};

export const utcToLocalDateTime = (dateString: string, hour: boolean): string => {
    let localDateString: string | Date = dateString;

    if (SHORT_DATE_REGEX.test(dateString)) {
        localDateString = momentDate(dateString, "YYYY-MM-DD").toDate();
    } else {
        // get instance dates missing 'Z' at the end
        if (!dateString.endsWith("Z")) {
            localDateString += "Z";
        }

        const dateObj = new Date(localDateString);

        if (dateObj.toString() === "Invalid Date") {
            localDateString = new Date(dateString); // dirty fix to stop console warning for moment falling back to date constructor
        } else {
            localDateString = dateObj;
        }
    }

    if (hour) {
        return formatDateTime(moment_timezone.tz(localDateString, timezone));
    }
    return formatDate(moment_timezone.tz(localDateString, timezone));
};

export const utcToLocalTime = (dateString: string): string => {
    let localDateString = dateString;

    // get instance dates missing 'Z' at the end
    if (!dateString.endsWith("Z")) {
        localDateString += "Z";
    }
    return formatTime(moment_timezone.tz(localDateString, timezone));
};

export const formatTimeInSeconds = (timeInSeconds: number) => {
    let timeString = BO_CORE_DEFAULT_VALUE;
    if (timeInSeconds > 0) {
        if (timeInSeconds < 3600) {
            const date = new Date(timeInSeconds * 1000).toISOString().substring(14, 14 + 5);
            const parts = date.split(":");
            const minutes = `${Number(parts[0]) > 0 ? `${Number(parts[0])} min` : ""}`;
            const seconds = `${Number(parts[1]) > 0 ? `${Number(parts[1])} ${minutes !== "" ? "s" : "sec"}` : ""}`;
            timeString = `${minutes} ${seconds}`;
        } else if (timeInSeconds > 86400) {
            timeString = "More than a day";
        } else {
            const date = new Date(timeInSeconds * 1000).toISOString().substring(11, 11 + 8);
            const parts = date.split(":");
            timeString = `${parts[0]} h ${parts[1]} m`;
        }
    }
    return timeString;
};

export const getDurationFromNow = (date: any) => {
    const duration = moment.duration(moment().diff(moment(date)));
    return {
        years: duration.years(),
        months: duration.months(),
        weeks: duration.weeks(),
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
    };
};

export const getDiffFromTodayAsYears = (date: any) => {
    return momentDate().startOf("day").diff(momentDate(date).startOf("day"), "years", true);
};

export const getDateDecreasedByDuration = (duration: number, timeDelimiter: moment.unitOfTime.DurationConstructor) => {
    return moment().subtract(moment.duration(duration, timeDelimiter)).format("MM/DD/YYYY HH:mm A Z");
};

export const representTimeDuration = (value: number, timeDelimiter: moment.unitOfTime.DurationConstructor) => {
    return moment.duration(value, timeDelimiter).humanize(false);
};

export const getDurationStringFromNow = (date: string) => {
    return momentDate.utc(date).fromNow();
};

export const convertFromUTCToLocal = (date: string) => {
    return momentDate.utc(date).local().toDate();
};

export const convertNewDate = () => {
    return momentDate(new Date());
};

// throws eslint error duplicate class members ...
// static getStartOfToday(): Date;
// static getStartOfToday(isMoment: false): Date;
// static getStartOfToday(isMoment: true): Moment;
// static getStartOfToday(isMoment?: boolean): Date | Moment {
//     const date = moment(new Date()).utc().startOf("day");
//     return isMoment ? date : date.toDate();
// }

export const getStartOfToday: Overload = (isMoment?: boolean): any => {
    const date = momentDate(new Date()).utc().startOf("day");
    return isMoment ? date : date.toDate();
};

export const getStartOfTodayUTC = (isMoment?: boolean) => {
    const date = moment_timezone.utc(new Date()).tz(timezone).startOf("day");
    return isMoment ? date : date.toDate();
};

export const setStartOfDay = (date: Date | null) => {
    const convertedDate = momentDate(date);
    if (!convertedDate.isValid()) {
        return null;
    }

    return convertedDate.startOf("day").toDate();
};

export const addDays = (date: Date, days: number): Date => {
    return momentDate(date).add(days, "days").toDate();
};

export const getDateAsISOStringInDefaultMomentFormat = (date: Date | null, isUTC?: boolean): string => {
    if (isUTC) {
        return momentDate(date).utc(true).format();
    }
    return momentDate(date).utc().format();
};

export const parseStringDateWithFormat = (date: string, format: string) => {
    return moment(date, format);
};

export const subtractAndFormat = (duration: number, unit: string, format: string) => {
    return moment()
        .subtract(duration as moment.DurationInputArg1, unit as moment.DurationInputArg2)
        .format(format);
};

export const subtractYearsAndFormatToDate = (duration: number): Date => {
    return momentDate().startOf("day").subtract(duration, "years").toDate();
};

export const subtractAndCheckIsBefore = (duration: number, unit: string, selectedDate: any) => {
    return momentDate()
        .subtract(duration as moment.DurationInputArg1, unit as moment.DurationInputArg2)
        .isBefore(moment(selectedDate));
};

export const isDateIsValid = (date: Date | string): boolean => {
    return momentDate(date).isValid();
};

export const formatDateTo = (date: any, format: any) => {
    if (momentDate.isMoment(date)) {
        const isValidD = date.isValid();
        return isValidD ? date.format(format) : "-";
    }
    const isValidDate = momentDate(date).isValid();
    return isValidDate ? momentDate(date).format(format) : "-";
};

export const formatDateToUTC = (date: string) => {
    return momentDate(date).utc();
};

export const formatCurrentDateTimeAsUTC = (date: string) => {
    return momentDate(date).utc(true);
};

export const getLocales = () => {
    return momentDate.locales();
};

export const getCurrentLocale = () => {
    return momentDate.locale();
};

export const durationToExactDate = (duration: any, unit: string) => {
    return momentDate().add(duration, unit).toDate();
};

export const addTimeToADate = (date: string, amount: any, timeType: any) => {
    return momentDate(date).add(amount, timeType).format();
};

export const checkDateYears = (selectedDate: any, targetDate: string) => {
    return momentDate(selectedDate).isSameOrBefore(targetDate);
};

export const checkDateYearsIsBefore = (selectedDate: any, targetDate: string) => {
    return momentDate(selectedDate).isBefore(momentDate(targetDate));
};

export const checkIfDateIsAfter = (selectedDate: any, targetDate: string) => {
    return momentDate(selectedDate).isAfter(targetDate);
};

export const checkIfDateStringIsAfter = (selectedDate: Date | null, targetDate: Date | null): boolean => {
    return momentDate(selectedDate).startOf("day").isAfter(momentDate(targetDate).startOf("day"));
};

export const checkIfDateIsAfterCurrentDate = (selectedDate: any): boolean => {
    const currentDate = moment();
    return moment(selectedDate).isAfter(currentDate);
};

// this method needs to be refactored
export const diffrentByDuration = (date: any) => {
    return momentDate.duration(momentDate().diff(momentDate.utc(date).local()));
};

export const genericGridDateFilteringRestApi = (filterModel: any, property: any, filterName: any, index: any, keys: any) => {
    if (filterModel[property]?.type === "inRange") {
        const formattedDateFrom = filterModel[property]?.dateFrom.split(" ")[0];
        const formattedDateTo = filterModel[property]?.dateTo.split(" ")[0];
        return `${property} =gt= ${formattedDateFrom} and ${property} =lt= ${formattedDateTo} ${index >= keys.length - 1 ? "" : "and "}`;
    }
    if (filterModel[property]?.type === "equals") {
        const formattedD = filterModel[property]?.dateFrom.split(" ")[0];
        // const helpingDate = addTimeToADate(formattedDate, 1, "days").split("+")[0];
        // const helpingDate = addTimeToADate(formattedDate, 1, "days").split("T")[0];
        // return `${property} =gt= '${formattedDate}' and ${property} =lt= '${helpingDate}' ${index >= keys.length - 1 ? "" : "and "}`;
        return `${property} == '${formattedD}' ${index >= keys.length - 1 ? "" : "and "}`;
    }
    const formattedDate = filterModel[property]?.dateFrom.split(" ")[0];
    return `${property} ${filterName} ${formattedDate} ${index >= keys.length - 1 ? "" : "and "}`;
};

export const genericGridDateFiltering = (filterModel: any, property: any, filterName: any): Object => {
    if (filterModel[property]?.type === "inRange") {
        const formattedDateFrom = filterModel[property]?.dateFrom.split(" ")[0];
        const formattedDateTo = filterModel[property]?.dateTo.split(" ")[0];
        // return `${property}:{greaterThan:"${formattedDateFrom}", lessThan: "${formattedDateTo}"},`;
        return {
            [property]: {
                greaterThan: formattedDateFrom,
                lessThan: formattedDateTo,
            },
        };
    }
    if (filterModel[property]?.type === "equals") {
        const formattedD = filterModel[property]?.dateFrom.split(" ")[0];
        const helpingDate = addTimeToADate(formattedD, 1, "days").split("+")[0];
        // return `${property}:{greaterThan:"${formattedD}", lessThan: "${helpingDate}"},`;
        return {
            [property]: {
                greaterThan: formattedD,
                lessThan: helpingDate,
            },
        };
    }
    const formattedDate = filterModel[property]?.dateFrom.split(" ")[0];
    // return `${property}:{${filterName}:"${formattedDate}"},`;
    return {
        [property]: {
            [filterName]: formattedDate,
        },
    };
};

export const sortByDateDesc = (a: string | Date, b: string | Date) => {
    const momentA = moment(a);
    const momentB = moment(b);

    if (momentA.isAfter(momentB)) {
        return -1;
    }

    if (momentB.isAfter(momentA)) {
        return 1;
    }

    return 0;
};

export const sortByDatesAsc = (a: string, b: string) => {
    const momentA = moment(a);
    const momentB = moment(b);

    if (momentA.isAfter(momentB)) {
        return 1;
    }

    if (momentB.isAfter(momentA)) {
        return BO_CORE_DEFAULT_NUMBER_VALUE;
    }

    return 0;
};

export const checkIsSame = (a: string, b: string) => {
    if (a.length === 0 && b.length === 0) {
        return true;
    }
    return momentDate(a).isSame(b);
};

export const getSelectedDateAsUTCWithFormat = (a: string, customFormat: string) => {
    return momentDate(a).utc().format(customFormat);
};

export const getUTCTimestampAsLocalDate = (a: string): Date => {
    return moment_timezone.utc(a).tz(timezone).toDate();
};

// static getLocalDateAsUTCwithFormat = (date: Date | string, customFormat: string): string => {
//     // return momentTz(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(customFormat);
//     return moment_timezone(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(customFormat);
// };

// static getDateInCurrentTimeZone = (a: string) => {
//     return moment_timezone(a).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).toDate();
//     // return momentTz(a).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).toDate();
// };

export const getDateInCurrentTimeZone = (a: string) => {
    return moment_timezone.tz(a, timezone).toDate();
};

export const getDateStringAsDate = (a: string): Date => {
    return momentDate(a).toDate();
};

export const isValidBirthDate = (date: string | null | undefined | object, format: string): boolean => {
    const isValidDate = momentDate(date, format).isValid();
    const yearGiven = momentDate(date, format);
    const currentYear = momentDate(new Date().toISOString().split("T")[0]);
    const differenceInYear = currentYear.diff(yearGiven, "years", true);
    const isValidAge = differenceInYear < MAX_USER_AGE;
    return isValidDate && isValidAge;
};

export const dateAsMoment = (date: any) => {
    return momentDate(date);
};

export const getCurrentDate = (): Date => {
    return momentDate().toDate();
};

export const getCurrentYear = (): number => {
    return momentDate().year();
};
