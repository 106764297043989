import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { APP_DEFAULT_LANGUAGE, LOCAL_STORAGE_ITEM_LANG, LOCAL_STORAGE_ITEM_LOCALE } from "../config/app.config";
import { LOCALE_DETAILS } from "../config/LocaleDetails";
import { BO_CORE_DEFAULT_NUMBER_VALUE } from "../utils/AppUtils";
import { getCurrentLocale, getLocales } from "../utils/DateTimeUtils";
import { AuthUserData, InsuranceInfoData, TenantData } from "../utils/appInterfaces/AppInterfaces";

export interface UserReducerState {
    // user: AuthUserData | null;
    user: any;
    // tenant: TenantData | null;
    tenant: any;
    locales: string[];
    locale: { value: string; label: string };
    lang: string;
    proctorStatus?: any;
    insuranceInfo?: InsuranceInfoData[];
    loading: boolean;
}

function getApplicationLocale(): { label: string; value: string } {
    function setLocaleToLocalStorage(): { label: string; value: string } {
        const localeTag = getCurrentLocale();
        const localeOption = {
            // label: AppUtils.getLocaleNameByTag(localeTag),
            label: LOCALE_DETAILS[localeTag] || localeTag,
            value: localeTag,
        };
        window.localStorage.setItem(LOCAL_STORAGE_ITEM_LOCALE, JSON.stringify(localeOption));
        return localeOption;
    }

    try {
        const locale: { label: string; value: string } = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_ITEM_LOCALE) as string);
        if (!locale) {
            return setLocaleToLocalStorage();
        }
        return locale;
    } catch (e) {
        return setLocaleToLocalStorage();
    }
}

function setLangToLocalStorage(lang: string): string {
    window.localStorage.setItem(LOCAL_STORAGE_ITEM_LANG, lang);
    return lang;
}

function getApplicationLang(): string {
    let lang = window.localStorage[LOCAL_STORAGE_ITEM_LANG];
    if (!lang) {
        // lang = navigator.language ? navigator.language.split("-")[0] : APP_DEFAULT_LANGUAGE;
        lang = APP_DEFAULT_LANGUAGE;
        setLangToLocalStorage(lang);
    }
    return lang;
}

const initialState: UserReducerState = {
    user: null,
    tenant: null,
    locales: getLocales(),
    locale: getApplicationLocale(),
    lang: getApplicationLang(),
    loading: false,
    // insuranceInfo: [],
};

const userReducer = createSlice({
    initialState,
    name: "userReducer",
    reducers: {
        // user;
        fetchAuthUser(state: UserReducerState, action: PayloadAction<boolean>) {
            return { ...state, loading: action.payload };
        },

        setAuthenticatedUser(state: UserReducerState, action: PayloadAction<AuthUserData | null>) {
            return { ...state, user: action.payload, loading: false };
        },

        // fetchAuthUserFailed(state: UserReducerState) {
        //     return { ...state, loading: false };
        // },

        // tenant
        setTenant(state: UserReducerState, action: PayloadAction<TenantData | null>) {
            return { ...state, tenant: action?.payload };
        },

        addNewTenant(state: UserReducerState, action: PayloadAction<{ id: number; name: string }>) {
            const userObj = { ...state.user, tenants: [...(state?.user?.tenants || []), action.payload] };
            return { ...state, user: userObj };
        },

        // locale;
        setApplicationLocale(state: UserReducerState, action: PayloadAction<{ value: string; label: string }>) {
            window.localStorage.setItem(LOCAL_STORAGE_ITEM_LOCALE, JSON.stringify(action.payload));
            return { ...state, locale: action?.payload };
        },

        // insurances
        setUserInsuranceInfo(state: UserReducerState, action: PayloadAction<InsuranceInfoData[]>) {
            return { ...state, insuranceInfo: action?.payload };
        },

        clearInsurancePictures(state: UserReducerState) {
            const insuranceUpdatedInfo = state?.insuranceInfo?.map((e) => ({
                ...e,
                insuranceFrontImage: { imageId: BO_CORE_DEFAULT_NUMBER_VALUE, name: "" },
                insuranceBackImage: { imageId: BO_CORE_DEFAULT_NUMBER_VALUE, name: "" },
            }));
            return { ...state, insuranceInfo: insuranceUpdatedInfo };
        },

        deleteNewUserInsurance(state: UserReducerState) {
            const updatedInsurances = state.insuranceInfo?.filter((insurance) => insurance !== undefined);
            return { ...state, insuranceInfo: updatedInsurances };
        },
    },
});

export const {
    fetchAuthUser,
    setAuthenticatedUser,
    // fetchAuthUserFailed,
    setTenant,
    setApplicationLocale,
    setUserInsuranceInfo,
    clearInsurancePictures,
    deleteNewUserInsurance,
    addNewTenant,
} = userReducer.actions;

export default userReducer.reducer;
