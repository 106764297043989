// import { legacy_createStore as createStore } from "redux";
import { StoreEnhancer, configureStore } from "@reduxjs/toolkit";
import { useSelector, TypedUseSelectorHook } from "react-redux";
import * as Sentry from "@sentry/react";
// import { devToolsEnhancer } from "@redux-devtools/extension";
// import baseReducer from "./reducers/baseReducer";
import { NODE_ENV } from "./config/app.config";
import spinnerReducer, { SpinnerReducerState } from "./reducers/spinnerReducer";
import userReducer, { UserReducerState } from "./reducers/userReducer";
import uiSliceReducer, { UiReducerState } from "./reducers/uiReducer";
import pageOverlayReducer, { PageOverlayReducerState } from "./reducers/pageOverlayReducer";

import LocalImageFilesReducer, { LocalImageFilesState } from "./reducers/localImageFilesReducer";
import ProductsReducer, { ProductItemsState } from "./reducers/ProductGroupManagementReducers/ProductsReducer";
import ProductsUiReducer, { ProductsUiState } from "./reducers/ProductGroupManagementReducers/ProductsUiReducer";
import PartnersReducer, { PartnersState } from "./reducers/ProductGroupManagementReducers/PartnersReducer";
import partnersUiReducer, { PartnersUiState } from "./reducers/ProductGroupManagementReducers/PartnersUiReducer";
import productGroupsReducer, { ProductGroupState } from "./reducers/ProductGroupManagementReducers/ProductGroupsReducer";
import productGroupsUiReducer, { ProductGroupsUiState } from "./reducers/ProductGroupManagementReducers/ProductGroupsUiReducer";
import carousellScrollUpdateReducer, { TCarousellScrollUpdateState } from "./reducers/CarousellScrollReducer/CarousellScrollReducer";

export interface BaseReducerState {
    spinnerReducer: SpinnerReducerState;
    userReducer: UserReducerState;
    localImageFiles: LocalImageFilesState;
    productsReducer: ProductItemsState;
    productsUiReducer: ProductsUiState;
    partnersReducer: PartnersState;
    partnersUiReducer: PartnersUiState;
    productGroupsReducer: ProductGroupState;
    productGroupsUiReducer: ProductGroupsUiState;
    pageOverlayReducer: PageOverlayReducerState;
    uiSliceReducer: UiReducerState;
    carousellScrollUpdateReducer: TCarousellScrollUpdateState;
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const storeInstance = configureStore<BaseReducerState>({
    reducer: {
        spinnerReducer,
        userReducer,
        localImageFiles: LocalImageFilesReducer,
        productsReducer: ProductsReducer,
        productsUiReducer: ProductsUiReducer,
        partnersReducer: PartnersReducer,
        partnersUiReducer,
        productGroupsReducer,
        productGroupsUiReducer,
        pageOverlayReducer,
        uiSliceReducer,
        carousellScrollUpdateReducer,
    },
    middleware: (defaultMiddleware) =>
        defaultMiddleware({
            /**
             * @see https://stackoverflow.com/questions/59944219/non-serializable-file-object-in-redux-action
             * even though files are technically serializable, storing files in redux is not exactly advisable
             */
            serializableCheck: {
                ignoredActions: ["localImageFiles/add"],
                ignoredPaths: ["localImageFiles.localImageFiles"],
            },
        }),
    enhancers:
        NODE_ENV === "development" ? undefined : (defaultEnhancers) => defaultEnhancers({ autoBatch: true }).concat(sentryReduxEnhancer as StoreEnhancer),

    devTools: NODE_ENV === "development",
});

const store = storeInstance;

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// https://react-redux.js.org/using-react-redux/static-typing#typing-the-useselector-hook
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
