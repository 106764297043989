import { createRoot } from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { StrictMode } from "react";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import SentryProfiler from "./layouts/SentryProfiler";
import IntlAppLayout from "./layouts/IntlAppLayout";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <StrictMode>
        <Provider store={store}>
            {process.env.NODE_ENV === "development" ? (
                <IntlAppLayout />
            ) : (
                <SentryProfiler>
                    <IntlAppLayout />
                </SentryProfiler>
            )}
        </Provider>
    </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
