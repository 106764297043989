import { createSlice } from "@reduxjs/toolkit";

export type TCarousellScrollUpdateState = {
    update: boolean;
};

const initialState: TCarousellScrollUpdateState = {
    update: false,
};

const caroucellScrollSlice = createSlice({
    initialState,
    name: "carousellScrollUpdateReducer",
    reducers: {
        toggleScroll(state: TCarousellScrollUpdateState) {
            return { ...state, update: !state.update };
        },
    },
});

export const { toggleScroll } = caroucellScrollSlice.actions;

export default caroucellScrollSlice.reducer;
